<template>
  <div class="careers">
    <div class="wrap" v-if="windowWidth">
      <div class="careersBox">
        <div class="vision">
          <span class="post">Senior Researcher of Computer Vision</span>
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >Research and invent algorithms for video content understanding
              and production</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Perform fundamental research on algorithm
              survey, evaluation, optimization, customization and innovation with
              practical requirements and insight
            </span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message">
              Actively engage with the academic and industry community for
              latest advances. Promote the performance optimization and
              implementation of computer vision and deep learning algorithms in
              various practical applications
            </span>
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message">
              The research outcomes with high academic value are encouraged to
              be submitted to top conferences and journals for presentation
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >PhD in Computer Science or related fields. 5+ years of research
              experience in academia or industry</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Familiar with at least one machine learning or deep learning
              platform with the experience of algorithm innovation and
              successful implementation in related fields</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >Proficient in English reading and writing excellent in
              communication and collabroation</span
            >
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message"
              >Strong publications in top conferences or journals,including
              CVPR, ICCV, ECCV, NeurIPS, <span style="font-style:oblique;display:inline-block">etc</span></span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="researcher">
          <span class="post"
            >Senior Researcher of Audio and Speech Processing</span
          >
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >Lead the research, incubation and application of audio and speech
              processing, including data processing, model trainig, technical
              reserach and innovation</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Advance the state of the art in cross-modal challenges with
              computer vision (CV) and natural language processing (NLP)</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message">
              Publish the latest achievements in top international
              conferences/journals with high influence in industry and academia
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >Computer-related major, with excellent skills in programming,
              mathematics, English and communication</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Passion in audio and speech processing with extensive
              experience</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >Awards in competitions or papers published in top
              conferences/journals, such as ICASSP, Interspeech, <span style="font-style:oblique;display:inline-block">etc</span></span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="nlp">
          <span class="post"
            >Senior Researcher of Natural Language Processing </span
          >
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >Lead the research, incubation and application of NLP
              technologies, including data processing, model training, technical
              research and algorithm innovation</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Collaborate with researchers from related fields such as computer
              vision and audio processing to tackle challenging projects</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message">
              Publish the latest innovative achievements in top journals with
              high influence in industry and academia
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <span class="message"
            >As an important part of intelligent content understanding and
            generation, the core technologies of natural language processing
            (NLP), including article classification, automatic summarization, 
           knowledge graph, emotion analysis, automatic question answering, 
            have broad application prospects in PCG
          </span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >PhD in computer science or related fields, with excellent skills
              in programming, mathematics, English and communication</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Passionate and productive in the above NLP related fields</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >Awards in related competitions or papers published in related
              fields (ACL, EMNLP, NeurlPS, <span style="font-style:oblique;display:inline-block">etc</span>.)</span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="reconstruction">
          <span class="post"
            >Senior Researcher of Computer Vision (3D reconstruction)</span
          >
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >Responsible for the tasks of extracting 3D structure and material
              information of camera, light source, human face, human body and
              general objects explicitly or implicitly from pictures or videos, 
              and use these information as independent output or to help other
              algorithms such as GAN generation for further implementation and
              optimization</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message">
              Perform data preparation, model training, algorithm research, 
              design and innovation during the research, incubation and landing
              stages of our technologies
            </span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message">
              Collborate with researchers from NLP, audio, speech and other visual
              fields to tackle challenging and exciting projects
              together, jointly build hard core technologies for intelligent
              content understanding and generation
            </span>
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message">
              Publish the latest innovative achievements on the top
              conferences/journals in related fields, and make influence in the
              industry and academia
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >PhD in computer science or related fields, with excellent
              educational background and skills in programming, mathematics, 
              English and communication</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Expert in Structure From Motion(SFM) algorithms, model-based 3D
              reconstruction algorithms, multi-view stereo vision methods or
              deep learning based 3D reconstruction methods</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >Experience in accurate reconstruction of dynamic 3D human body
              and face is a plus</span
            >
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message"
              >Experience in computer graphics, 3D object modeling and rendering
              is a plus</span
            >
          </p>
          <p class="careers_text">
            <span class="number">5.</span
            ><span class="message">Passionate in the above related fields</span>
          </p>
          <p class="careers_text">
            <span class="number">6.</span
            ><span class="message"
              >Experience in winning competitions and published top papers in
              related fields (CVPR, ICCV, SIGGRAPH, <span style="font-style:oblique;display:inline-block">etc</span>.)</span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="development">
          <span class="post">AI Backend Engineer</span>
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >Design, develop, and deploy backend systems for computer vision
              algorithms, optimize code for efficiency and scalability</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message">
              Work with algorithm R&D and product partners to make the services
              meet the business requirements. Maintain and optimize the services
              as required
            </span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message">
              Design the specifications for the interface of services, 
              custormize the services for different product partners
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >Bachelor or above in computer science or related field</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Good programming skill and proficient in: Python, C++, with Linux
              development experience</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >Working experience in machine learning, deep learning related
              service design and development, and service communication
              development based on HTTP and RPC; Experience in developing and
              using common databases such as mysql, redis, mongodb, <span style="font-style:oblique;display:inline-block">etc</span>; Familiar
              with distributed system design, caching,message queuing, <span style="font-style:oblique;display:inline-block">etc</span>;
              Experience in common deep learning frameworks,such as
              pytorch, tensorflow, <span style="font-style:oblique;display:inline-block">etc</span>.,and knowledge in the basics of deep neural
              networks; Experience in computer vision algorithm development and
              background deployment is preferred; Experience in opencv
              development, CUDA development and model inference acceleration is
              a bonus</span
            >
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message"
              >Good communication and collabraration skill and strong sense of
              responsibility</span
            >
          </p>
          <p class="careers_text">
            <span class="number">5.</span
            ><span class="message"
              >Strong interest and passion in technology innovation</span
            >
          </p>
        </div>
      </div>
      <div class="contact">
        <span class="contact_us">Welcome to contact us via QR code</span>
        <div class="code_box">
          <p class="qr_code">
            <img src="../../../assets/img/contact.png" alt="" />
          </p>
          <p class="qr_code">
            <img src="../../../assets/img/Subscription.png" alt="" />
          </p>
        </div>
      </div>
    </div>
    <div class="wrap_mini" v-if="windowWidth == false">
      <div class="careersBox">
        <div class="vision">
          <span class="post">Senior Researcher of Computer Vision</span>
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >Research and invent algorithms for video content understanding
              and production</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Perform fundamental research on algorithm
              survey, evaluation, optimization, customization and innovation with
              practical requirements and insight
            </span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message">
              Actively engage with the academic and industry community for
              latest advances. Promote the performance optimization and
              implementation of computer vision and deep learning algorithms in
              various practical applications
            </span>
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message">
              The research outcomes with high academic value are encouraged to
              be submitted to top conferences and journals for presentation
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >PhD in Computer Science or related fields. 5+ years of research
              experience in academia or industry</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Familiar with at least one machine learning or deep learning
              platform with the experience of algorithm innovation and
              successful implementation in related fields</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >Proficient in English reading and writing, excellent in
              communication and collabroation</span
            >
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message"
              >Strong publications in top conferences or journals, including
              CVPR, ICCV, ECCV, NeurIPS, <span style="font-style:oblique;display:inline-block">etc</span></span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="researcher">
          <span class="post"
            >Senior Researcher of Audio and Speech Processing</span
          >
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >Lead the research, incubation and application of audio and speech
              processing, including data processing, model trainig, technical
              reserach and innovation</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Advance the state of the art in cross-modal challenges with
              computer vision (CV) and natural language processing (NLP)</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message">
              Publish the latest achievements in top international
              conferences/journals with high influence in industry and academia
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >Computer-related major, with excellent skills in programming, 
              mathematics, English and communication</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Passion in audio and speech processing with extensive
              experience</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >Awards in competitions or papers published in top
              conferences/journals, such as ICASSP, Interspeech, <span style="font-style:oblique;display:inline-block">etc</span></span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="nlp">
          <span class="post"
            >Senior Researcher of Natural Language Processing </span
          >
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >Lead the research, incubation and application of NLP
              technologies, including data processing, model training, technical
              research and algorithm innovation</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Collaborate with researchers from related fields such as computer
              vision and audio processing to tackle challenging projects</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message">
              Publish the latest innovative achievements in top journals with
              high influence in industry and academia
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <span class="message"
            >As an important part of intelligent content understanding and
            generation, the core technologies of natural language processing
            (NLP), including article classification, automatic summarization, 
            knowledge graph, emotion analysis, automatic question answering, 
            have broad application prospects in PCG
          </span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >PhD in computer science or related fields, with excellent skills
              in programming, mathematics, English and communication</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Passionate and productive in the above NLP related fields</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >Awards in related competitions or papers published in related
              fields (ACL, EMNLP, NeurlPS, <span style="font-style:oblique;display:inline-block">etc</span>.)</span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="reconstruction">
          <span class="post"
            >Senior Researcher of Computer Vision (3D reconstruction)</span
          >
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >Responsible for the tasks of extracting 3D structure and material
              information of camera, light source, human face, human body and
              general objects explicitly or implicitly from pictures or videos, 
              and use these information as independent output or to help other
              algorithms such as GAN generation for further implementation and
              optimization</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message">
              Perform data preparation, model training, algorithm research, 
              design and innovation during the research, incubation and landing
              stages of our technologies
            </span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message">
              Collborate with researchers from NLP, audio, speech and other visual
              fields to tackle challenging and exciting projects
              together, jointly build hard core technologies for intelligent
              content understanding and generation
            </span>
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message">
              Publish the latest innovative achievements on the top
              conferences/journals in related fields, and make influence in the
              industry and academia
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >PhD in computer science or related fields, with excellent
              educational background and skills in programming, mathematics, 
              English and communication</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Expert in Structure From Motion(SFM) algorithms, model-based 3D
              reconstruction algorithms, multi-view stereo vision methods or
              deep learning based 3D reconstruction methods</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >Experience in accurate reconstruction of dynamic 3D human body
              and face is a plus</span
            >
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message"
              >Experience in computer graphics, 3D object modeling and rendering
              is a plus</span
            >
          </p>
          <p class="careers_text">
            <span class="number">5.</span
            ><span class="message">Passionate in the above related fields</span>
          </p>
          <p class="careers_text">
            <span class="number">6.</span
            ><span class="message"
              >Experience in winning competitions and published top papers in
              related fields (CVPR, ICCV, SIGGRAPH, <span style="font-style:oblique;display:inline-block">etc</span>.)</span
            >
          </p>
        </div>
        <div class="line"></div>
        <div class="development">
          <span class="post">AI Backend Engineer</span>
          <span class="post_type">{{jobResponsibilities}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >Design, develop, and deploy backend systems for computer vision
              algorithms, optimize code for efficiency and scalability</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message">
              Work with algorithm R&D and product partners to make the services
              meet the business requirements. Maintain and optimize the services
              as required
            </span>
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message">
              Design the specifications for the interface of services, 
              custormize the services for different product partners
            </span>
          </p>
          <span class="job_requirements">{{jobRequirements}}</span>
          <p class="careers_text">
            <span class="number">1.</span
            ><span class="message"
              >Bachelor or above in computer science or related field</span
            >
          </p>
          <p class="careers_text">
            <span class="number">2.</span
            ><span class="message"
              >Good programming skill and proficient in: Python, C++, with Linux
              development experience</span
            >
          </p>
          <p class="careers_text">
            <span class="number">3.</span
            ><span class="message"
              >Working experience in machine learning, deep learning related
              service design and development, and service communication
              development based on HTTP and RPC; Experience in developing and
              using common databases such as mysql, redis, mongodb, <span style="font-style:oblique;display:inline-block">etc</span>; Familiar
              with distributed system design, caching, message queuing,  <span style="font-style:oblique;display:inline-block">etc</span>;
              Experience in common deep learning frameworks, such as
              pytorch, tensorflow, <span style="font-style:oblique;display:inline-block">etc</span>., and knowledge in the basics of deep neural
              networks; Experience in computer vision algorithm development and
              background deployment is preferred; Experience in opencv
              development, CUDA development and model inference acceleration is
              a bonus</span
            >
          </p>
          <p class="careers_text">
            <span class="number">4.</span
            ><span class="message"
              >Good communication and collabraration skill and strong sense of
              responsibility</span
            >
          </p>
          <p class="careers_text">
            <span class="number">5.</span
            ><span class="message"
              >Strong interest and passion in technology innovation</span
            >
          </p>
        </div>
      </div>
      <div class="contact">
        <span class="contact_us">Welcome to contact us via QR code</span>
        <span class="qr_code">
          <img src="../../../assets/img/contact.png" alt="" />
          <img src="../../../assets/img/Subscription.png" alt="" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "../../../assets/js/eventBus";
export default {
  // 监听页面宽度
  created() {
    window.addEventListener("resize", this.getWidth);
    this.getWidth();
  },
  destroyed() {
    window.removeEventListener("resize", this.getWidth);
  },
  mounted() {
    bus.$emit("sendBybus", this.bannerHeight);
    bus.$emit("textBybus", this.text);
  },
  data() {
    return {
      bannerHeight: 400,
      text: "Careers",
      windowWidth: true,
      jobResponsibilities:"Job responsibilities",
      jobRequirements:"Job requirements"
    };
  },
  methods: {
    // 获取页面宽度
    getWidth() {
      if (window.innerWidth > 1032) {
        this.windowWidth = true;
      } else {
        this.windowWidth = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.careers {
  width: 100%;
  font-size: 0.16rem;
  .wrap {
    max-width: 1200px;
    margin: 0 auto;
    span {
      display: block;
    }
    .careersBox {
      margin-top: 0.6rem;
      margin-left: 0.96rem;
      margin-right: 0.96rem;
      .development,
      .researcher,
      .nlp,
      .reconstruction,
      .vision {
        .post {
          height: 0.4rem;
          line-height: 0.4rem;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.28rem;
          color: #222222;
        }
        .post_type,
        .job_requirements {
          margin: 0.5rem 0 0.1rem;
          height: 0.28rem;
          line-height: 0.28rem;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.2rem;
          color: #222222;
        }
        .careers_text {
          position: relative;
          padding-left: 0.2rem;
        }
        .message {
          line-height: 0.32rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.16rem;
          color: #666666;
        }
        .number {
          display: inline-block;
          line-height: 0.32rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.16rem;
          color: #666666;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: #e5e5e5;
      border-radius: 0.06rem;
      margin: 0.56rem 0 0.5rem;
    }
    .contact {
      margin-top: 0.6rem;
      .contact_us {
        height: 0.22rem;
        line-height: 0.22rem;
        font-family: PingFangSC-Regular, sans-serif;
        font-size: 0.16rem;
        color: #666666;
        text-align: center;
      }
      .code_box {
        text-align: center;
        .qr_code {
          display: inline-block;
          margin: 0.3rem 0.2rem 0.6rem;
          width: 2.2rem;
          height: 2.2rem;
          text-align: center;
          border: 1px solid #e5e5e5;
          border-radius: 1px;
          img {
            height: 1.84rem;
            width: 1.84rem;
            margin: 0.18rem;
          }
        }
      }
    }
  }
  .wrap_mini {
    width: 7.5rem;
    margin: 0 auto;
    span {
      display: block;
    }
    .careersBox {
      padding: 1rem 0.8rem 0;
      .vision,
      .development,
      .researcher,
      .nlp,
      .reconstruction {
        margin-bottom: 1rem;
        .post {
          line-height: 0.67rem;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.48rem;
          color: #222222;
        }
        .post_type,
        .job_requirements {
          margin: 0.6rem 0 0.2rem;
          line-height: 0.5rem;
          font-family: PingFangSC-Semibold, sans-serif;
          font-size: 0.36rem;
          color: #222222;
        }
        .careers_text {
          position: relative;
          padding-left: 0.35rem;
        }
        .message {
          line-height: 0.52rem;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.28rem;
          color: #666666;
        }
        .number {
          display: inline-block;
          font-family: PingFangSC-Regular, sans-serif;
          font-size: 0.28rem;
          line-height: 0.52rem;
          color: #666666;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
    .line {
      width: 100%;
      height: 1px;
      background: #e5e5e5;
      margin-bottom: 1rem;
    }
    .contact {
      .contact_us {
        height: 0.52rem;
        line-height: 0.52rem;
        font-family: PingFangSC-Regular, sans-serif;
        font-size: 0.28rem;
        color: #666666;
        text-align: center;
      }
      .qr_code {
        margin: 0.6rem auto 1rem;
        width: 4.4rem;
        text-align: center;
        border: 0.02rem solid #e5e5e5;
        border-radius: 0.02rem;
        img {
          height: 3.68rem;
          width: 3.68rem;
          margin: 0.36rem;
        }
      }
    }
  }
}
</style>